
import { AgGridVue } from "ag-grid-vue3";
import { LicenseManager } from "ag-grid-enterprise";
LicenseManager.setLicenseKey(process.env.VUE_APP_AGGRID_KEY as string);

import { computed, defineComponent } from "vue";
import { useStore } from "vuex";

import { fullPageSetup } from "../aggridfullpage/AgGridFullPageSetup";
import { handleAgGrid } from "../aggridfullpage/AgGridFullPage";

export default defineComponent({
  name: "AgGrid",
  components: {
    AgGridVue,
  },
  props: {
    id: String,
    widgetClasses: String,
    pageTitle: String,
    agTheme: {
      type: String,
      default: 'ag-theme-alpine'
    },
    enableCardHeader: {
      type: Boolean,
      default: true
    },
    enableSearch: {
      type: Boolean,
      required: false,
    },
    hideHeader: {
      type: Boolean,
      required: false,
    },
    customGroupColumnDefs: {
      type: Object,
      required: false,
    },
    customColumnDefs: {
      type: Array,
      required: true,
    },
    customBodyOptions: {
      type: Object,
      required: true,
    },
    defaultGroup: {
      type: String,
      required: false,
    },
    enableEmptyRow: {
      type: Boolean,
      required: false
    },
    gridApi: {
      type: Object,
      required: true
    },
    colApi: {
      type: Object,
      required: true,
    },
    aggridData: {
      type: Array,
      required: true,
      default: () => [],
    },
    frameworkComponents: {
      type: Object,
      required: false,
    },
    enableRowGroup: {
      type: Boolean,
      required: false,
    },
    enableStatusBar: {
      type: Boolean,
      required: false,
    },
    footerRowData: {
      type: Array,
      required: false,
    },
  },
  emits: [
    "colApiReady",
    "gridApiReady",
    "cellValueChanged",
    "rowValueChanged",
    "displayedColumnsChanged",
    "onSelectionChanged",
    "deleteItemEmit"
  ],
  setup(props, { emit }) {
    const store = useStore();
    const componentKey = computed(() => store.state.BodyModule.componentKey);
    const {
      getRowHeight,
      bwTableFull,
      bwTableBody,
      bwElements,
      handleTableHeight,
      rowHeight,
      search,
      searchData,
      tableHeight,
      getRowNodeId,
      defaultColDef,
    } = fullPageSetup(props);
    const {
      rowData,
      columnDefs,
      onRowValueChanged,
      handleClipboardData,
      groupRowRendererParams,
      onRowEditingStarted,
      onRowEditingStopped,
      onCellValueChanged,
      selectedRow,
      edittedRowData,
      getContextMenuItems,
      statusBar,
      getMainMenuItems,
      isRowGroupEnabled,
      autoGroupColumnDef,
      currentGroupedColumn,
      getGroupedColumn,
      deleteEmit,
      displayedColumnsChanged,
      columnResized,
      onFirstDataRendered
    } = handleAgGrid(props, emit);

    return {
      handleClipboardData,
      onFirstDataRendered,
      groupRowRendererParams,
      componentKey,
      getRowHeight,
      bwTableFull,
      bwTableBody,
      bwElements,
      handleTableHeight,
      rowHeight,
      search,
      searchData,
      tableHeight,
      getRowNodeId,
      defaultColDef,
      rowData,
      columnDefs,
      onRowValueChanged,
      onRowEditingStarted,
      onRowEditingStopped,
      onCellValueChanged,
      selectedRow,
      edittedRowData,
      getContextMenuItems,
      statusBar,
      getMainMenuItems,
      isRowGroupEnabled,
      autoGroupColumnDef,
      currentGroupedColumn,
      getGroupedColumn,
      deleteEmit,
      displayedColumnsChanged,
      columnResized,
    };
  },
});
