<template>
  <div>
    <img
      v-if="params.node.field === 'tenant'"
      class="rounded img-thumbnail me-3"
      :src="`media/svg/brand-logos/${value.valueFormatted.toLowerCase()}.svg`"
      onerror="javascript:this.src='media/svg/brand-logos/No_image_available.svg'"
      width="25"
    />
    <span class="groupTitle">{{ value.valueFormatted || value.value }}</span>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onBeforeMount } from "vue";

export default defineComponent({
  name: "AgGridGroupRowInnerRenderer",
  props: ['params'],
  setup(props) {
    const value = ref(props.params);

    return {
      value,
    };
  },
});
</script>

<style>
#action-switch-label {
  justify-content: center;
}
</style>