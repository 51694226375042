/* eslint-disable @typescript-eslint/class-name-casing */
/* eslint-disable @typescript-eslint/camelcase */
// import DATA from "@/assets/json/olympics.json";
import { reactive, ref, computed, onMounted } from "vue";
import { useStore } from "vuex";
import { useDebounceFn } from "@vueuse/core";
import { Actions as AggridActions } from "@/store/enums/AggridEnums";
import {
  ColDef,
  ColumnApi,
  GridApi,
  MenuItemDef,
  GroupCellRendererParams,
  IGroupCellRenderer,
  ValueFormatterParams,
} from "ag-grid-community";
import { checkForRowPinned } from "./AgGridFullPageSetup";
import AgGridGroup from "./custom-components/AgGridGroupRow.vue";
import { ProjectData } from "./interface/AgGridFullPage.interface";

declare global {
  interface Window {
    SBU_PAGE?: any;
  }
}

export const handleAgGrid = (props, emit) => {
  const store = useStore();
  const sortArray = ref<string[]>([]);
  const rowData = computed(() => {
    return props.aggridData;
  });
  // const rowData = computed(() => {
  //   return props.aggridData.filter(
  //     (project) => project.[Project.projectActivation] === 1
  //   );
  // });
  const currentGroupedColumn = ref(props.defaultGroup);
  const autoGroupColumnDef = reactive<ColDef>({
    minWidth: 200,
    headerName: currentGroupedColumn.value,
    menuTabs: [],
  });

  const groupRowRendererParams = reactive<any>({
    innerRendererFramework: AgGridGroup,
  });

  const columnDefs = computed((): ColDef[] => {
    return props.customColumnDefs;
  });

  const statusBar = reactive({
    statusPanels: [{ statusPanel: "agAggregationComponent", align: "right" }],
  });

  // Other Variables
  const edittedRowData = ref<ProjectData | null>(null);
  const selectedRow = ref();

  const getGroupedColumn = computed(() => {
    return columnDefs.value.filter((col) => col.rowGroup);
  });

  const isRowGroupEnabled = computed(() => {
    return getGroupedColumn.value.length > 0;
  });

  const addEmptyRow = (gridApi) => {
    // console.log(props.gridApi);
    if (!gridApi) return;
    console.log("adding empty row");

    // gridApi.applyTransaction({
    //   add: [{}],
    // });
    // gridApi.redrawRows();
  };

  const onFirstDataRendered = (params) => {
    console.log("first data rendered");
    // console.log(params.api);
    params.api.sizeColumnsToFit();

    if (props.enableEmptyRow) {
      addEmptyRow(params.api);
    }
  };

  const onRowEditingStarted = (params) => {
    // console.log(params);
    const data = params.data;
    edittedRowData.value = { ...data };
    console.log("rowEditingStarted");
  };

  const onCellValueChanged = (params) => {
    console.log("onCellValueChanged");
    emit("cellValueChanged", params);
  };

  const onRowValueChanged = (params) => {
    console.log("onRowValueChanged");
    // console.log(params.data);
    // console.log(edittedRowData.value);
    const oldData = JSON.stringify(edittedRowData.value);
    const newData = JSON.stringify(params.data);
    // console.log("oldData", oldData);
    // console.log("newData", newData);
    // const data = params.data;
    emit("rowValueChanged", {
      oldData,
      newData,
      params,
    });
  };

  const onRowEditingStopped = (event) => {
    console.log("onRowEditingStopped");
    edittedRowData.value = null;
  };

  const deleteEmit = () => {
    emit("deleteItemEmit", {
      gridApi: props.gridApi,
      selectedRow: selectedRow.value[0].data,
    });
  };

  const unGroupColumn = (colName) => {
    const index = columnDefs.value.findIndex(
      (col) => col.headerName === colName
    );
    currentGroupedColumn.value = undefined;
    const clonedColumnDefs = [...columnDefs.value];
    clonedColumnDefs[index].rowGroup = false;
    clonedColumnDefs[index].hide = false;
    props.gridApi.setColumnDefs(clonedColumnDefs);
    store.dispatch(AggridActions.UPDATE_COMPONENTKEY_ACTION);
  };

  const groupByColumn = (colName) => {
    // Check to see if there is already a grouped column and ungroup them
    if (currentGroupedColumn.value && currentGroupedColumn.value !== colName) {
      console.log("ungroup");
      unGroupColumn(currentGroupedColumn.value);
    }
    const index = columnDefs.value.findIndex(
      (col) => col.headerName === colName
    );
    const clonedColumnDefs = [...columnDefs.value];
    currentGroupedColumn.value = clonedColumnDefs[index].headerName;
    clonedColumnDefs[index].rowGroup = true;
    clonedColumnDefs[index].hide = true;
    props.gridApi.setColumnDefs(clonedColumnDefs);
    store.dispatch(AggridActions.UPDATE_COMPONENTKEY_ACTION);
  };

  /**
   * Add Multisort
   * Check if sorted column exists in multi sort array
   * Push column name to array
   * Add ability to unsort a single column
   */

  const getSortIcon = (sortDir?: string) => {
    console.log(sortDir);
    if (!sortDir)
      return '<span><i class="fas fa-arrows-alt-v fa-lg"></i></span>';

    if (sortDir === "asc") {
      return '<span><i class="fas fa-long-arrow-alt-up fa-lg"></i></span>';
    } else {
      return '<span><i class="fas fa-long-arrow-alt-down fa-lg"></i></span>';
    }
  };

  const sortByColumn = (colId: string, sortDir?: string) => {
    const dir = ["asc", "desc"];

    if (dir.includes(sortDir!)) {
      if (sortDir === "asc") {
        props.colApi.applyColumnState({
          state: [
            {
              colId: colId,
              sort: "desc",
            },
          ],
          defaultState: { sort: null },
        });
      } else {
        props.colApi.applyColumnState({
          state: [
            {
              colId: colId,
              sort: "asc",
            },
          ],
          defaultState: { sort: null },
        });
      }
    } else {
      props.colApi.applyColumnState({
        state: [
          {
            colId: colId,
            sort: "asc",
          },
        ],
        defaultState: { sort: null },
      });
    }
  };

  const getMainMenuItems = (params): MenuItemDef[] => {
    console.log(params);
    const colName = params.column.colDef.headerName;
    const menu = params.defaultItems.slice(0);
    if (props.enableRowGroup) {
      menu.push("separator");
      menu.push({
        name: `${
          currentGroupedColumn.value === colName
            ? `Ungroup ${colName}`
            : `Group By ${colName}`
        }`,
        action: () => {
          currentGroupedColumn.value !== colName
            ? groupByColumn(colName)
            : unGroupColumn(currentGroupedColumn.value);
        },
      });
    }
    menu.push("separator");
    menu.push({
      name: `Sort ${colName}`,
      action: () => {
        sortByColumn(params.column.colId, params.column.sort);
      },
      icon: getSortIcon(params.column.sort),
    });
    menu.push({
      name: `Clear Sort`,
      action: () => {
        props.colApi.applyColumnState({
          defaultState: { sort: null },
        });
      },
    });
    return menu;
  };

  const getContextMenuItems = (params) => {
    if (!checkForRowPinned(params.node)) {
      params.node.setSelected(true);
    }
    const result = [
      "copy",
      {
        name: "Copy Row",
        action: function() {
          params.api.copySelectedRowsToClipboard(true);
        },
        icon: '<span class="ag-icon ag-icon-copy" unselectable="on"></span>',
      },
      "separator",
      "paste",
      {
        name: "Delete Cell",
        shortcut: "DEL",
        disabled: checkForRowPinned(params.node) ? true : false,
        action: () => {
          const focusedCell = params.api.getFocusedCell();
          console.log(focusedCell);
          params.api.startEditingCell({
            rowIndex: focusedCell.rowIndex,
            colKey: focusedCell.column.colId,
            keyPress: 46,
          });
        },
        icon: '<i class="bi bi-trash"></i>',
      },
      {
        name: "Delete Row",
        disabled: checkForRowPinned(params.node) ? true : false,
        action: () => {
          deleteEmit();
        },
        icon: '<i class="bi bi-trash"></i>',
      },
      "separator",
      "excelExport",
    ];
    return result;
  };

  const columnResized = useDebounceFn(() => {
    emit("displayedColumnsChanged");
  }, 1000);

  const displayedColumnsChanged = useDebounceFn((params) => {
    emit("displayedColumnsChanged");
  }, 1000);

  const handleClipboardData = (params) => {
    if (!props.gridApi) return;
    if (params.data.length === 0) return;
    // console.log(params);
    if (params.data[0].length === 1) return params.data;
    // console.log(props.gridApi.gridOptionsWrapper.gridOptions);
    const emptyLastRow =
      params.data[params.data.length - 1][0] === "" &&
      params.data[params.data.length - 1].length === 1;

    if (emptyLastRow) {
      params.data.splice(params.data.length - 1, 1);
    }

    const lastIndex = props.gridApi.getLastDisplayedRow();
    // let rowCount = props.gridApi.getDisplayedRowCount();
    const focusedCell = props.gridApi.getFocusedCell();
    const focusedIndex = focusedCell!.rowIndex;
    const treeOptionEnabled = props.gridApi.gridOptionsWrapper.gridOptions;

    if (focusedIndex + params.data.length - 1 > lastIndex) {
      // console.log("inside");
      const resultLastIndex = focusedIndex + (params.data.length - 1);
      const addRowCount = resultLastIndex - lastIndex;
      let rowsToAdd = [] as any;
      let addedRows = 0;
      let currIndex = params.data.length - 1;
      while (addedRows < addRowCount) {
        rowsToAdd.push(params.data.splice(currIndex, 1)[0]);
        addedRows++;
        currIndex--;
      }
      rowsToAdd = rowsToAdd.reverse();
      const newRowData = [] as any;
      rowsToAdd.map((r) => {
        const row = {};
        let currColumn = focusedCell!.column;
        r.map((i) => {
          // console.log(currColumn.getColDef());
          const nodeId = Math.random()
            .toString(36)
            .substring(2, 20);

          row[currColumn.getColDef().field!] = i;

          // Add randomly generated string for row node id
          if (
            currColumn.getColDef().field! === "doc_no" ||
            currColumn.getColDef().field! === "id"
          ) {
            row[currColumn.getColDef().field!] = nodeId;
          }

          if (currColumn.getColDef().field! === "name" && treeOptionEnabled) {
            row[currColumn.getColDef().field!] = `${i} ${nodeId}`;
          }

          currColumn = props.customBodyOptions.columnApi.getDisplayedColAfter(
            currColumn
          )!;
        });
        newRowData.push(row);
      });
      props.gridApi.applyTransaction({ add: newRowData });
    }

    // Remove dragCol
    const columnsArray = props.colApi
      .getAllGridColumns()
      .filter((col) => col.visible);
    // console.log(columnsArray);
    const columns = columnsArray.map((col) => col.colId);
    // console.log(columns);

    const row = {};

    // check if value already exists and count up (1)
    for (let i = 0; i < columns.length; i++) {
      // if (params.data[0].length < columns.length) return params.data;
      const nodeId = Math.random()
        .toString(36)
        .substring(2, 20);
      // console.log(`${i}:${params.data[0][i]}`);

      // if (!columns[i]) return;
      // if (params.data.length === 0) return;
      row[columns[i]] = params.data[0][i];

      if (columns[i] === "doc_no" || columns[i] === "id") {
        row[columns[i]] = nodeId;
      }

      if (columns[i] === "ag-Grid-AutoColumn" && treeOptionEnabled) {
        row[columns[i]] = `${params.data[0][i]} ${nodeId}`;
      }

      // if (columns[i] === "dragCol") {
      //   row[columns[i]] = null;
      // }

      // if (columns[i] === "name" && treeOptionEnabled) {
      //   row[columns[i]] = `${params.data[0][i]} ${nodeId}`;
      // }
    }
    // console.log(
    //   JSON.stringify(row, null /*replacer function */, 4 /* space */)
    // );
    // console.log([Object.values(row)]);
    return [Object.values(row)];
  };

  return {
    handleClipboardData,
    checkForRowPinned,
    groupRowRendererParams,
    onFirstDataRendered,
    rowData,
    columnDefs,
    onRowValueChanged,
    onRowEditingStarted,
    onRowEditingStopped,
    onCellValueChanged,
    selectedRow,
    edittedRowData,
    getContextMenuItems,
    statusBar,
    getMainMenuItems,
    isRowGroupEnabled,
    autoGroupColumnDef,
    getGroupedColumn,
    currentGroupedColumn,
    deleteEmit,
    displayedColumnsChanged,
    columnResized,
  };
};
