
import { defineComponent, ref, onBeforeMount } from "vue";

export default defineComponent({
  name: "AgGridGroupRowInnerRenderer",
  props: ['params'],
  setup(props) {
    const value = ref(props.params);

    return {
      value,
    };
  },
});
