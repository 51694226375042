/* eslint-disable @typescript-eslint/class-name-casing */
/* eslint-disable @typescript-eslint/camelcase */
// import DATA from "@/assets/json/olympics.json";
import {
  reactive,
  ref,
  onUpdated,
  onUnmounted,
  onMounted,
  computed,
  nextTick,
} from "vue";
import { GridApi, ColumnApi, ColDef, GridOptions } from "ag-grid-community";
import {
  BWElements,
  Header,
  Footer,
  Body,
  BodyScrollable,
  Full,
  StatusBar,
} from "./interface/AgGridFullPage.interface";
import { useDebounceFn } from "@vueuse/core";

declare global {
  interface Window {
    SBU_PAGE?: any;
  }
}

export const checkForRowPinned = (node) => {
  if (node.rowPinned === "bottom") {
    return true;
  }
  return false;
};

export const fullPageSetup = (props) => {
  // Initialize variables for tableheight and rowheight
  const tableHeight = ref<number>(0);
  const rowHeight = ref<number>(0);

  // Initialize variables for column definitions
  const defaultColDef = ref<ColDef>({
    sortable: true,
    resizable: true,
    filter: true,
    flex: 1,
    enableCellChangeFlash: true,
    editable: (params) => !checkForRowPinned(params.node),
    headerComponentParams: {
      template: `
      <div class="ag-cell-label-container" role="presentation">
        <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>
        <div class="ag-header-cell-label" role="presentation">
          <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>
          <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>
          <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>
          <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
          <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>
        </div>
      </div>
      `,
    },
  });

  // Initialize variables for full page setup
  const activeHandlers = ref<Array<any>>([]);
  const bwTableFull = ref<HTMLDivElement | any>(null);
  const bwTableBody = ref<HTMLElement | any>(null);
  const bwTableFooter = ref<HTMLElement | any>(null);
  const bodyScrollable = ref<HTMLElement | any>(null);
  const bwHeader = ref<HTMLDivElement | any>(null);
  const bwStatusBar = ref<HTMLDivElement | any>(null);
  const position = reactive({
    header: {
      isFixed: false,
      release: {},
    },
    body: {
      isFixed: false,
      release: {},
    },
    footer: {
      isFixed: false,
      release: {},
    },
  });
  let bwElements = reactive<BWElements>({
    full: bwTableFull,
    header: bwHeader,
    body: bwTableBody,
    bodyScrollable: bodyScrollable,
    footer: bwTableFooter,
    statusBar: bwStatusBar,
  });

  const search = ref<string | number>("");

  const getRowNodeId = (node) => {
    return node.id || node.doc_no;
  };

  const getRowHeight = async (params) => {
    await nextTick();
    const headerElem = document.querySelector(".ag-header");
    if (!params && params.node.rowHeight === 0) {
      rowHeight.value = Number(headerElem?.clientHeight);
      return;
    }
    rowHeight.value = Number(headerElem?.clientHeight);
  };

  const getRowCount = computed(() => {
    return props.gridApi.getDisplayedRowCount() + 2;
  });

  const heightDifference = (rowCount: number) => {
    const el = document.querySelector(".bw-table-body") as HTMLDivElement;
    return rowHeight.value * rowCount - el.offsetHeight;
  };

  const handleTableHeight = () => {
    console.log(props.aggridData.length);
    // AgGrid adds some extra height so we need to delete the extra spacing
    tableHeight.value =
      rowHeight.value * props.aggridData.length -
      heightDifference(props.aggridData.length);
  };

  const searchData = (e) => {
    search.value = e.target.value;
    props.gridApi.setQuickFilter(search.value);
    handleTableHeight();
  };

  const logBoundingRect = (
    tablePos,
    tableHeaderRect,
    tableFooterRect,
    navbar
  ) => {
    console.log("=====NAVBAR=====");
    console.log(navbar);
    console.log("=====TABLE=====");
    console.log(tablePos);
    console.log("=====TABLE HEADER=====");
    console.log(tableHeaderRect);
    console.log("=====TABLE FOOTER=====");
    console.log(tableFooterRect);
  };

  const handleFixedHeaderCheck = (
    tablePos,
    tableViewport,
    toolbarHeaderRect,
    tableHeaderRect,
    tableFooterRect,
    globalHeaderRect
  ): void => {
    const header = bwElements.header as Header["header"];
    // Height of navigation header height and toolbar height

    const navbar = globalHeaderRect.height;
    const { left: vpLeft, right: vpRight } = tableViewport;

    // logBoundingRect(
    //   tablePos,
    //   tableHeaderRect,
    //   tableFooterRect,
    //   globalHeaderRect
    // );

    const offsetTop = document.getElementById(`${props.id}`)!.offsetTop;
    if (tableFooterRect.height !== 0) {
      if (
        tableHeaderRect.x + tableHeaderRect.height - 50 >= tablePos.top &&
        tableHeaderRect.bottom <= tableFooterRect.top &&
        tablePos.bottom - navbar > offsetTop
      ) {
        header.style!.position = "fixed";
        header.style!.top = navbar + "px";
        header.style!.left = vpLeft + "px";
        header.style!.width = vpRight - vpLeft + "px";

        header.classList!.add("bw-table-header-fixed");

        // }
      } else {
        header.style!.position = "relative";
        header.style!.top = "initial";
        header.style!.left = "initial";

        header!!.classList.remove("bw-table-header-fixed");
      }
    } else {
      if (
        tablePos.top <=
          globalHeaderRect.top + globalHeaderRect.height + rowHeight.value &&
        tablePos.top + tablePos.height > globalHeaderRect.top &&
        tablePos.bottom - navbar > offsetTop
      ) {
        // console.log("hi");
        header.style!.position = "fixed";
        header.style!.top = navbar + "px";
        header.style!.left = vpLeft + "px";
        header.style!.width = vpRight - vpLeft + "px";

        header.classList!.add("bw-table-header-fixed");

        // }
      } else {
        header.style!.position = "relative";
        header.style!.top = "initial";
        header.style!.left = "initial";

        header!!.classList.remove("bw-table-header-fixed");
      }
    }
  };

  const handleFixedFooterCheck = (
    tablePos,
    tableViewport,
    tableHeaderRect,
    tableFooterRect,
    tableStatusbarRect
  ) => {
    const { footer } = bwElements as Footer;

    // logBoundingRect(tablePos, tableHeaderRect, tableFooterRect);
    const totalHeaderHeight = 120;
    const totalFooterHeight =
      tableFooterRect.height + tableStatusbarRect.height;

    if (
      tablePos.bottom - totalHeaderHeight + rowHeight.value >=
        tableViewport.bottom &&
      tableFooterRect.top > tableHeaderRect.bottom &&
      tablePos.top <= tableViewport.bottom
    ) {
      footer.style!.position = "fixed";
      footer.style!.top = tableViewport.bottom - totalFooterHeight + "px";
      footer.style!.left = tableViewport.left + "px";
      footer.style!.width = tableViewport.right - tableViewport.left + "px";
      footer.style!.zIndex = 3;
    } else {
      // console.log("release footer");

      footer.style!.position = "relative";
      footer.style!.bottom = "0px";
      footer.style!.top = "initial";
      footer.style!.left = "initial";

      footer.style!.zIndex = 3;
    }
  };

  const handleFixedStatusBarCheck = (
    tablePos,
    tableViewport,
    tableHeaderRect,
    tableStatusBarRect,
    tableFooterRect
  ) => {
    const { statusBar } = bwElements as StatusBar;
    const totalFooterHeight =
      tableFooterRect.height + tableStatusBarRect.height;
    if (
      tablePos.bottom - 10 >= tableViewport.bottom &&
      tableStatusBarRect.top > tableHeaderRect.bottom &&
      tablePos.top <= tableViewport.bottom + totalFooterHeight
    ) {
      statusBar.style!.position = "fixed";
      statusBar.style!.top =
        tableViewport.bottom - tableStatusBarRect.height + "px";
      statusBar.style!.left = tableViewport.left + "px";
      statusBar.style!.width = tableViewport.right - tableViewport.left + "px";
      statusBar.style!.border = 1 + "px solid #babfc7";

      statusBar.style!.zIndex = 3;
    } else {
      // console.log("release statusBar");

      statusBar.style!.position = "fixed";
      statusBar.style!.top =
        tableViewport.bottom - tableStatusBarRect.height + "px";
      statusBar.style!.left = tableViewport.left + "px";
      statusBar.style!.width = tableViewport.right - tableViewport.left + "px";
      statusBar.style!.border = 1 + "px solid #babfc7";
      statusBar.style!.zIndex = 3;
    }
  };

  const handleBodyPosition = (tablePos, tableViewport) => {
    const { body } = bwElements as Body;
    const { bodyScrollable } = bwElements as BodyScrollable;

    const headerHeight = rowHeight.value;
    let prop;
    const scroll = tableViewport.top - tablePos.top;
    const neededScroll = headerHeight;

    if (scroll > neededScroll) {
      if (!position.body.isFixed) {
        // console.log(`fix ${props.id} body`);

        position.body.release = {
          position: body.style!.position,
          top: body.style!.top,
          left: body.style!.left,
          width: body.style!.zIndex,
        };

        body.style!.position = "relative";
        // body.style!.top = tableViewport.top + headerHeight + "px";

        body.style!.bottom = "auto";
        body.style!.left = "initial";
        body.style!.width = tableViewport.right - tableViewport.left + "px";
        body.style!.zIndex = 2; //less than header and footer

        position.body.isFixed = true;
      }
    } else {
      if (position.body.isFixed) {
        for (prop in position.body.release) {
          body.style![prop] = position.body.release[prop];
        }

        body.style!.bottom = "0px";

        position.body.isFixed = false;
      }
    }
    //set body scroll
    const bodyScroll = scroll - (tableViewport.top + headerHeight);
    bodyScrollable.scrollTop = bodyScroll;
  };

  const setPositions = () => {
    // console.log("setpos");
    const { full } = bwElements as Full;

    const elm = full;
    const tableRect = elm.getBoundingClientRect();

    // Get Global Header
    const globalHeader = document.querySelector(
      "div#kt_header"
    ) as HTMLDivElement;
    const globalHeaderRect = globalHeader?.getBoundingClientRect();

    // Get Global Toolbar
    const toolbarHeader = document.querySelector(
      "div.toolbar"
    ) as HTMLDivElement;
    const toolbarHeaderRect = toolbarHeader?.getBoundingClientRect();

    const tableHeaderRect = bwHeader.value.getBoundingClientRect();

    const tableFooterRect = bwTableFooter.value.getBoundingClientRect();

    const tableStatusbarRect = bwStatusBar.value.getBoundingClientRect();

    const tableViewport = window.SBU_PAGE.main.get_viewport(elm);
    // setTimeout(() => {
    //header fixed check
    handleFixedHeaderCheck(
      tableRect,
      tableViewport,
      toolbarHeaderRect,
      tableHeaderRect,
      tableFooterRect,
      globalHeaderRect
    );

    //footer fixed check
    handleFixedFooterCheck(
      tableRect,
      tableViewport,
      tableHeaderRect,
      tableFooterRect,
      tableStatusbarRect
    );

    handleFixedStatusBarCheck(
      tableRect,
      tableViewport,
      tableHeaderRect,
      tableStatusbarRect,
      tableFooterRect
    );

    //body position
    handleBodyPosition(tableRect, tableViewport);
    // }, 500);
  };

  const initFullPage = () => {
    bodyScrollable.value = bwTableBody.value.querySelector(
      ".ag-body-viewport"
    ) as HTMLElement;
    bwHeader.value = bwTableBody.value.querySelector(
      ".ag-header"
    ) as HTMLElement;
    bwTableFooter.value = bwTableBody.value.querySelector(
      ".ag-floating-bottom"
    ) as HTMLElement;
    bwStatusBar.value = bwTableBody.value.querySelector(".ag-status-bar");
    // console.log(bwTableFooter.value);
    bwElements = {
      full: bwTableFull.value,
      header: bwHeader.value,
      body: bwTableBody.value,
      bodyScrollable: bodyScrollable.value,
      footer: bwTableFooter.value,
      statusBar: bwStatusBar.value,
    };

    if (activeHandlers.value.length !== 0) return;
    setPositions();
    window.addEventListener("scroll", setPositions);
    if (!props.gridApi) return;

    props.gridApi?.addEventListener("rangeSelectionChanged", () => {
      setPositions();
    });
  };

  onMounted(() => {
    initFullPage();
    handleTableHeight();
  });

  onUnmounted(() => {
    window.removeEventListener("scroll", setPositions);
    props.gridApi.removeEventListener("rangeSelectionChanged", () => {
      setPositions();
    });
  });

  onUpdated(() => {
    initFullPage();
    // console.log("updated");
    // handleTableHeight(getRowCount.value);
  });

  return {
    getRowHeight,
    getRowCount,
    bwTableFull,
    bwTableBody,
    bwElements,
    handleTableHeight,
    rowHeight,
    search,
    searchData,
    tableHeight,
    getRowNodeId,
    defaultColDef,
  };
};
