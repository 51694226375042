<template>
  <div class="row gy-5 g-xl-8">
    <div class="col-xxl-12">
      <!--begin::Aggrid Fullpage Widget-->
      <div :class="widgetClasses" class="card card-xxl-stretch mt-5">
        <!--begin::Header-->
        <div class="card-header border-0 px-0" v-if="enableCardHeader">
          <h3 class="card-title align-items-start flex-column" v-if="pageTitle">
            <span class="card-label fw-bolder fs-3 mb-1">{{ pageTitle }}</span>
          </h3>
          <div class="w-100 card-toolbar justify-content-between align-items-end">
            <div v-if="enableSearch" class="w-25">
              <input
                type="text"
                class="form-control form-control-lg form-control-solid w-200px"
                name="appName"
                placeholder="Start typing to search table"
                :value="search"
                @input="searchData"
              />
            </div>
            <slot name="toolbar-content" :id="id" :selectedRow="selectedRow" :gridApi="gridApi"></slot>
          </div>
        </div>
        <!--end::Header-->

        <!--begin::Body-->
        <div class="card-body pt-3 px-0">
          <!--begin::Table container-->

          <!-- <AnimatedIcon /> -->

          <div
            class="table-responsive bw-table"
            ref="bwTableFull"
            :style="`min-height: ${tableHeight + 'px'
            }; width: 100%; overflow: hidden;`"
          >
            <!--begin::Table Body-->
            <div
              :id="id"
              class="bw-table-body"
              ref="bwTableBody"
              style="position: relative; width: 100%; top: 0"
              :key="componentKey"
            >
              <AgGridVue
                style="width: 100%; height: 100%;"
                :class="agTheme"
                undoRedoCellEditing="true"
                undoRedoCellEditingLimit="20"
                enableCellChangeFlash="true"
                suppressClickEdit="false"
                domLayout="autoHeight"
                @row-value-changed="onRowValueChanged"
                @row-editing-started="onRowEditingStarted"
                @row-editing-stopped="onRowEditingStopped"
                @cell-value-changed="onCellValueChanged"
                @displayedColumnsChanged="displayedColumnsChanged"
                @columnResized="columnResized"
                @firstDataRendered="onFirstDataRendered"
                :processDataFromClipboard="handleClipboardData"
                :groupRowRendererParams="groupRowRendererParams"
                :id="`aggrid-${id}`"
                :row-data="rowData"
                :column-defs="customColumnDefs"
                :grid-options="customBodyOptions"
                :getRowHeight="getRowHeight"
                :getRowNodeId="getRowNodeId"
                :defaultColDef="defaultColDef"
                :frameworkComponents="frameworkComponents"
                :suppressCopyRowsToClipboard="false"
                :stopEditingWhenCellsLoseFocus="true"
                :getContextMenuItems="getContextMenuItems"
                :pinnedBottomRowData="footerRowData"
                :getMainMenuItems="getMainMenuItems"
                :animateRows="true"
                :statusBar="statusBar"
              />
            </div>
            <!--end::Table Body-->
          </div>
          <!--end::Table container-->
        </div>
        <!--begin::Body-->
      </div>
      <!--end::Aggrid Fullpage Widget -->
    </div>
  </div>
</template>
<script lang="ts">
import { AgGridVue } from "ag-grid-vue3";
import { LicenseManager } from "ag-grid-enterprise";
LicenseManager.setLicenseKey(process.env.VUE_APP_AGGRID_KEY as string);

import { computed, defineComponent } from "vue";
import { useStore } from "vuex";

import { fullPageSetup } from "../aggridfullpage/AgGridFullPageSetup";
import { handleAgGrid } from "../aggridfullpage/AgGridFullPage";

export default defineComponent({
  name: "AgGrid",
  components: {
    AgGridVue,
  },
  props: {
    id: String,
    widgetClasses: String,
    pageTitle: String,
    agTheme: {
      type: String,
      default: 'ag-theme-alpine'
    },
    enableCardHeader: {
      type: Boolean,
      default: true
    },
    enableSearch: {
      type: Boolean,
      required: false,
    },
    hideHeader: {
      type: Boolean,
      required: false,
    },
    customGroupColumnDefs: {
      type: Object,
      required: false,
    },
    customColumnDefs: {
      type: Array,
      required: true,
    },
    customBodyOptions: {
      type: Object,
      required: true,
    },
    defaultGroup: {
      type: String,
      required: false,
    },
    enableEmptyRow: {
      type: Boolean,
      required: false
    },
    gridApi: {
      type: Object,
      required: true
    },
    colApi: {
      type: Object,
      required: true,
    },
    aggridData: {
      type: Array,
      required: true,
      default: () => [],
    },
    frameworkComponents: {
      type: Object,
      required: false,
    },
    enableRowGroup: {
      type: Boolean,
      required: false,
    },
    enableStatusBar: {
      type: Boolean,
      required: false,
    },
    footerRowData: {
      type: Array,
      required: false,
    },
  },
  emits: [
    "colApiReady",
    "gridApiReady",
    "cellValueChanged",
    "rowValueChanged",
    "displayedColumnsChanged",
    "onSelectionChanged",
    "deleteItemEmit"
  ],
  setup(props, { emit }) {
    const store = useStore();
    const componentKey = computed(() => store.state.BodyModule.componentKey);
    const {
      getRowHeight,
      bwTableFull,
      bwTableBody,
      bwElements,
      handleTableHeight,
      rowHeight,
      search,
      searchData,
      tableHeight,
      getRowNodeId,
      defaultColDef,
    } = fullPageSetup(props);
    const {
      rowData,
      columnDefs,
      onRowValueChanged,
      handleClipboardData,
      groupRowRendererParams,
      onRowEditingStarted,
      onRowEditingStopped,
      onCellValueChanged,
      selectedRow,
      edittedRowData,
      getContextMenuItems,
      statusBar,
      getMainMenuItems,
      isRowGroupEnabled,
      autoGroupColumnDef,
      currentGroupedColumn,
      getGroupedColumn,
      deleteEmit,
      displayedColumnsChanged,
      columnResized,
      onFirstDataRendered
    } = handleAgGrid(props, emit);

    return {
      handleClipboardData,
      onFirstDataRendered,
      groupRowRendererParams,
      componentKey,
      getRowHeight,
      bwTableFull,
      bwTableBody,
      bwElements,
      handleTableHeight,
      rowHeight,
      search,
      searchData,
      tableHeight,
      getRowNodeId,
      defaultColDef,
      rowData,
      columnDefs,
      onRowValueChanged,
      onRowEditingStarted,
      onRowEditingStopped,
      onCellValueChanged,
      selectedRow,
      edittedRowData,
      getContextMenuItems,
      statusBar,
      getMainMenuItems,
      isRowGroupEnabled,
      autoGroupColumnDef,
      currentGroupedColumn,
      getGroupedColumn,
      deleteEmit,
      displayedColumnsChanged,
      columnResized,
    };
  },
});
</script>

<style lang="scss">
@import "~ag-grid-community/dist/styles/ag-grid.css";
@import "~ag-grid-community/dist/styles/ag-theme-alpine.css";
@import "~ag-grid-community/dist/styles/ag-theme-material.css";
</style>

<style lang="css">
@media only screen and (min-width: 1400px) {
  .card__wrapper > .card__filter {
    position: fixed;
    top: 140px;
    width: 29rem;
  }
}

.ag-row-inline-editing {
  background-color: #ccf5e2 !important;
  border: 2px solid #41dc94 !important;
}

.ag-status-bar {
  background-color: white;
  border-top: 0px !important;
  font-size: 12px;
  line-height: 0.5 !important;
}

.ag-cell-inline-editing {
  margin-top: -1px;
  height: 40px !important;
}

/* .ag-center-cols-viewport {
  height: inherit !important;
} */

.ag-theme-alpine .ag-ltr .ag-cell {
  text-align: left;
  border-right: 2px solid #dbdbdb;
  border-right-width: 1px;
}

.ag-header-cell-menu-button {
  cursor: pointer;
}

.ag-header,
.ag-header-viewport {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

/* .ag-header-cell {
  border-right: 1px solid #dbdbdb;
  cursor: initial;
} */

.bw-table-body .ag-body-viewport {
  overflow: hidden !important;
  margin-bottom: 40px;
  transition: all 1s ease-out;
}

/* .bw-table-body .ag-body-horizontal-scroll-viewport {
  overflow: hidden !important;
} */

.bw-table-header-fixed {
  border: 1px solid rgb(186, 191, 199);
  border-top: none;
  z-index: 3;
  transform: translateZ(0);
  -webkit-transform: translateZ(0);
}

.bw-table-body {
  margin-bottom: 40px;
}

.ag-floating-bottom {
  background-color: white;
  border: 1px solid rgb(219, 219, 219) !important;
  /* transition: all 0.5s ease-out; */
  box-shadow: 0 -0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.isNegative {
  color: white;
  background-color: #dc3545;
}

/* .ag-layout-auto-height {
  min-height: 200px !important;
}

.bw-table-body {
  min-height: 360px;
}

#aggrid-table1 {
  min-height: 360px;
} */
</style>